import React, { useState, useEffect } from "react";
import { Fireworks } from "@fireworks-js/react";
import axios from "axios";
import classes from "./NumberSpinner.module.css";

const NumberSpinner = (props) => {
  const [index, setIndex] = useState(0);
  const [currentNumber, setCurrentNumber] = useState(0);
  const [startSpinner, setStartSpinner] = useState(false);
  const [showFireworks, setShowFireworks] = useState(false);
  const [numbersToAnimate, setNumbersToAnimate] = useState([0]);
  const [selectedGift, setSelectedGift] = useState('');
  const [selectedWinner, setSelectedWinner] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    const handleKeyDown = async(event) => {
      if (event.code === "Space" || event.code === "Enter") {
        if(!startSpinner){
          await fetchNumbers();
        }

        if(!buttonDisabled){
          setStartSpinner((prevStartSpinner) => !prevStartSpinner);
          if (startSpinner) {
            setShowFireworks(true);
            saveLuckyDrawWinner(currentNumber);
          } else {
            setShowFireworks(false);
          }
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonDisabled, currentNumber, startSpinner]);

  useEffect(() => {
    let interval;
    if (startSpinner && numbersToAnimate.length > 0) {
      interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % numbersToAnimate.length);
      }, 1);
    }
    return () => clearInterval(interval);
  }, [startSpinner, numbersToAnimate]);

  useEffect(() => {
    setCurrentNumber(numbersToAnimate[index]);
  }, [index, numbersToAnimate]);

  const handleButtonClick = async () => {
    if(!startSpinner){
      await fetchNumbers();
    }
    setStartSpinner((prevStartSpinner) => !prevStartSpinner);

    if (startSpinner) {
      setShowFireworks(true);
      saveLuckyDrawWinner(currentNumber);
    } else {
      setShowFireworks(false);
    }
  };

  const fetchNumbers = async () => {
    try {
      const response = await axios.get(
        "https://draw.happy.mv/api/staff-list"
      );
      setNumbersToAnimate(response.data.userDetails);
      if(response.data.gifts === 0){
        setButtonDisabled(true);
        setNumbersToAnimate([0]);
      }
    } catch (error) {
      console.error("Error fetching numbers from API:", error);
    }
  };

  const saveLuckyDrawWinner = async (number) => {
    try {
      const response = await axios.post(
        "https://draw.happy.mv/api/save-winner",
        { winnerNumber: number }
      );

      if(response.data.status === 1){
        props.onSaveWinnerData(true);
        setSelectedGift(response.data.gift);
        setSelectedWinner(response.data.winner);
      }
      console.log("Number sent to backend:", number);
    } catch (error) {
      console.error("Error sending number to backend:", error);
    }
  };

  const renderNumber = (number) => {
    const paddedNumber = String(number).padStart(4, "0");

    const digitArray = paddedNumber.split("").map((digit, index) => (
      <div className={classes.digit} key={index}>
        {digit}
      </div>
    ));

    return digitArray;
  };

  return (
    <>
      <div className={classes.spinner}>
        {showFireworks && (
          <div className={classes.winner}>
            <h3>{selectedGift}</h3>
            <h2>
              <span>W</span>INNER
            </h2>
            <h1>{selectedWinner}</h1>
          </div>
        )}
        <div className={`${classes.digits} ${startSpinner ? "spinning" : ""}`}>
          {renderNumber(currentNumber)}
        </div>
        <button onClick={handleButtonClick} disabled={buttonDisabled}>
          {startSpinner && !buttonDisabled ? "Stop Spinner" : "Start Spinner"}
        </button>
      </div>
      {showFireworks && (
        <Fireworks
          options={{ opacity: 0.5 }}
          style={{
            top: 0,
            rght: 0,
            width: "50%",
            height: "100%",
            position: "fixed",
            backgroundImage: "../../public/assets/Background1.jpg",
            backgroundSize: "cover"
          }}
        />
      )}
    </>
  );
};

export default NumberSpinner;
