import React from "react";

import { FaCrown } from "react-icons/fa";
import classes from "./Gift.module.css";

const Gift = (props) => {
  const winner = props.winner != null ? props.winner : "Pending";
  const staff = props.staff != null ? props.staff : "";
  return (
    <section className={classes.card}>
      <div className={classes["card-details"]}>
        <h3 className={classes["card-title"]}>{props.name}</h3>
        <div className={classes["card-other"]}>
          <h2 className={classes["card-winner-text"]}><FaCrown /> &nbsp;Winner</h2>
          <h2 className={classes["card-winner"]}>{winner}</h2>
          <h3 className={classes["card-staff"]}>{staff}</h3>
        </div>
      </div>
    </section>
  );
};

export default Gift;
