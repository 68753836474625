import React from "react";

import Gift from "./Gift";
import classes from "./WinnerList.module.css";

const WinnerList = (props) => {
  return (
    <section className={classes['winners-list']}>
      {props.listWinner.map((gift) => (
        <Gift
            key={gift.gift_id}
            name={gift.gift_name}
            winner={gift.gift_winner}
            staff={gift.gift_winner_name}
        />
      ))}
    </section>
  );
};

export default WinnerList;
