import NumberSpinner from "./NumberSpinner";
import WinnerList from "./WinnerList";
import { useEffect, useState } from "react";
import { GiPodiumWinner } from "react-icons/gi";
import axios from "axios";
import './Home.css'

const Home = () => {
  const [selectedWinner, setSelectedWinner] = useState(false);
  const [listWinner, setListWinner] = useState([]);

  const saveWinnerHandler = async (isSaveWinner) => {
    setSelectedWinner(isSaveWinner);
    await fetchWinnerList();
  };

  useEffect(() => {
    fetchWinnerList();
  }, [selectedWinner]);

  const fetchWinnerList = async () => {
    try {
      const response = await axios.get(
        "https://draw.happy.mv/api/gift-list"
      );

      setListWinner(response.data.winnerDetails);
    } catch (error) {
      console.error("Error fetching numbers from API:", error);
    }
  };

  const iconStyle = {
    transform: 'scaleX(-1)',
  };

  return (
    <div className="lucky">
      <div className="winner">
        <h1><GiPodiumWinner /> &nbsp;Lucky Draw Winners&nbsp; <GiPodiumWinner style={iconStyle}/></h1>
        <div className="winner-list-container">
          <WinnerList listWinner={listWinner} isSelected={selectedWinner}/>
        </div>
      </div>
      <div className="spinner">
        <NumberSpinner onSaveWinnerData={saveWinnerHandler}/>
      </div>
    </div>
  );
}

export default Home;