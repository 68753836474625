import { useState } from "react";
import { Zoom } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Home from "./components/Home";
import axios from "axios";
import * as Yup from "yup";
import "./App.css";

import logo from "./assets/logo.png";

function App() {
  const [loginSuccess, setLoginSuccess] = useState(false);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("User Name is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    try {
      const response = await axios.post(
		"https://draw.happy.mv/api/login",
        values
      );

      if (response.data.status === 1) {
        setLoginSuccess(true);
      } else {
        setLoginSuccess(false);
      }

      setTimeout(() => {
        resetForm();
        setSubmitting(false);
      }, 500);
    } catch (error) {
      setLoginSuccess(false);
    }
  };

  const handleDoubleClick = () => {
    const elem = document.documentElement;
    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        console.log(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  return (
    <div onDoubleClick={handleDoubleClick}>
      {loginSuccess && (
        <Zoom in={loginSuccess} timeout={1000}>
          <div className="test">
            <Home />
          </div>
        </Zoom>
      )}
      {!loginSuccess && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="main">
              <div className="card">
                <section className="header">
                  <img src={logo} alt="Logo" />
                  <h1>Login</h1>
                </section>
                <section className="body">
                  <Field
                    type="text"
                    name="username"
                    placeholder="Enter Username"
                  />
                  <div className="error">
                    <ErrorMessage className="error" name="username" />
                  </div>
                  <Field
                    type="password"
                    name="password"
                    placeholder="Enter Password"
                  />
                  <div className="error">
                    <ErrorMessage className="error" name="password" />
                  </div>
                  <button type="submit">LOGIN</button>
                  <h5>Solution by Happymarket - ICT. © 2024</h5>
                </section>
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
}

export default App;
